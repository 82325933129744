// action types
import SecureApiService from "../secureapi.service";
import i18n from "@/core/plugins/vue-i18n";

export const PULL_LOGS = "elastic/logs";

export const LOGS_DATA = "logs";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  logs: [],
  headers: []
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_LOGS](context) {
    state.loading = true;
    return SecureApiService.get("api_logs")
      .then(({ data }) => {
        state.loading = false;
        context.commit(LOGS_DATA, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [LOGS_DATA](state, data) {
    state.loading = false;
    state.logs = data.data;
    state.headers = data.headers;
  },
  [REFRESH_ACTION](state) {
    state.documents = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
