import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import i18n from "@/core/plugins/vue-i18n";

export const PULL_USERS = "get/users";
export const PULL_ALL_USERS = "get/all_users";
export const REMOVE_USER = "remove/user";
export const INVITE_USER = "invite/user";
export const CHECK_INVITE = "invite/check";
export const GET_MY_INVITES = "invite/my_list";
export const UPDATE_GROUPS = "update/groups";

export const PULL_USER = "get/user";

export const DELETE_USER = "delete/user";
export const BLOCK_USER = "block/user";
export const UNBLOCK_USER = "unblock/user";

export const ACCEPT_INVITE = "invite/accept";
export const DECLINE_INVITE = "invite/decline";

export const USERS_DATA = "users";
export const USER_MODEL = "user";
export const ALL_USERS_DATA = "all_users";
export const MY_INVITES = "users";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";
export const REFRESH_MODEL = "refreshModel";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  disabled: false,
  message: "",
  users: [],
  model: { user_groups: [] },
  all_users: []
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_USERS](context) {
    state.loading = true;
    return SecureApiService.get("users")
      .then(({ data }) => {
        state.loading = false;
        if (data.err == 2) {
          state.disabled = true;
        }

        context.commit(USERS_DATA, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [PULL_ALL_USERS](context) {
    state.loading = true;
    return SecureApiService.get("users/all")
      .then(({ data }) => {
        state.loading = false;
        if (data.err == 2) {
          state.disabled = true;
        }

        context.commit(ALL_USERS_DATA, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [GET_MY_INVITES](context) {
    state.loading = true;
    return new Promise(resolve => {
      SecureApiService.get("invite/my_list")
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }

          resolve(data.data);
          state.loading = false;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data.errors);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading = false;
        });
    });
  },
  [INVITE_USER](context, data) {
    state.loading = true;
    return SecureApiService.post("user/invite", data)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [REMOVE_USER](context, data) {
    state.loading = true;
    return SecureApiService.post("user/remove", data)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [BLOCK_USER](context, user_id) {
    state.loading = true;
    return SecureApiService.update("user/block", user_id)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [UNBLOCK_USER](context, user_id) {
    state.loading = true;
    return SecureApiService.update("user/unblock", user_id)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [DELETE_USER](context, user_id) {
    state.loading = true;
    return SecureApiService.delete("user/delete", user_id)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [CHECK_INVITE](context, data) {
    state.loading = true;
    return SecureApiService.post("user/invite/check", data)
      .then(({ data }) => {
        if (data.err === 1) {
          context.commit(SET_MESSAGE, data);
        }
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [ACCEPT_INVITE](context, data) {
    state.loading = true;
    return SecureApiService.post("user/invite/accept", data)
      .then(({ data }) => {
        if (data.err === 1) {
          context.commit(SET_MESSAGE, data);
        }
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [PULL_USER](context, id) {
    state.loading = true;
    return SecureApiService.get("user", id)
      .then(({ data }) => {
        context.commit(USER_MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [DECLINE_INVITE](context, data) {
    state.loading = true;
    return SecureApiService.post("user/invite/accept", data)
      .then(({ data }) => {
        if (data.err === 1) {
          context.commit(SET_MESSAGE, data);
        }
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [UPDATE_GROUPS](context, data) {
    state.loading = true;
    return SecureApiService.update("user/groups", data.id, data.groups)
      .then(({ data }) => {
        if (data.err === 1) {
          context.commit(SET_MESSAGE, data);
        }
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  }
};

const mutations = {
  [USERS_DATA](state, data) {
    state.loading = false;
    state.users = data.data;
  },
  [ALL_USERS_DATA](state, data) {
    state.loading = false;
    state.all_users = data.data;
  },
  [REFRESH_ACTION](state) {
    state.loading = false;
  },
  [REFRESH_MODEL](state) {
    state.model = { user_groups: [] };
  },
  [USER_MODEL](state, data) {
    state.loading = false;
    state.model = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
