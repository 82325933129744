// action types
import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import i18n from "@/core/plugins/vue-i18n";

export const POST_COLLECTION = "post/collection/";
export const PUT_COLLECTION = "put/collection/";
export const DELETE_COLLECTION = "delete/collection/";
export const RECREATE_COLLECTION = "recreate/collection/";
export const UPDATE_COLLECTION = "update/collection/";

export const PULL_COLLECTIONS = "get/collections/";
export const PULL_COLLECTION = "get/collection/";
export const PULL_COLLECTIONS_PREDEFINED = "get/collection/predefined";

export const COLLECTIONS = "collections";
export const COLLECTION = "collection";
export const COLLECTIONS_PREDEFINED = "collection_predefined";

export const COLLECTION_SELECTED = "collection_selected";
export const COLLECTION_SELECTED_ACTION = "collection_selectedAction";
export const COLLECTION_REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";
export const COLLECTION_RELOAD_DATA = "collectionReload";

export const CLOSE_DIALOG = "closeDialog";
export const CLOSE_DIALOG_ACTION = "closeDialogAction";

class Collection {
  constructor(id, name, force_stopwords, relevant_rate, mapping) {
    this.id = id;
    this.name = name;
    this.force_stopwords = force_stopwords;
    this.mapping = mapping;
    this.relevant_rate = {
      name: relevantMapper[parseInt(relevant_rate)],
      value: parseInt(relevant_rate)
    };
  }
}

class CollectionPredefined {
  constructor(id, name, description, schema) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.schema = schema;
  }
}

const relevantMapper = {
  0: "OFF",
  10: "10%",
  20: "20%",
  30: "30%",
  40: "40%",
  50: "50%",
  60: "60%",
  70: "70%",
  80: "80%",
  90: "90%",
  100: "ALL"
};

const state = {
  collections: [],
  collections_predefined: [],
  collection: new Collection(0, "", false, 70),
  errors: [],
  hasMessage: false,
  needCollection: false,
  showDialog: false,
  loading: true,
  collectionSelected: new Collection(0, "", false, 70),
  message: "",
  relevantMapper
};

const getters = {
  selectedCollection(state) {
    return state.collectionSelected && state.collectionSelected.id > 0
      ? state.collectionSelected
      : state.collections.length > 0
      ? state.collections[0]
      : [];
  },
  collections(state) {
    return state.collections;
  }
};

const actions = {
  [COLLECTION_REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [CLOSE_DIALOG](context) {
    context.commit(CLOSE_DIALOG_ACTION);
  },
  [COLLECTION_SELECTED](context, collection) {
    context.commit(COLLECTION_SELECTED_ACTION, collection);
  },
  [POST_COLLECTION](context, credentials) {
    state.loading = true;
    return new Promise(resolve => {
      SecureApiService.post("collection", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }

          context.commit(COLLECTION, data);
          resolve(data.data);
          state.loading = false;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data.errors);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading = false;
        });
    });
  },
  [PUT_COLLECTION](context, credentials) {
    state.loading = true;
    return new Promise(resolve => {
      SecureApiService.update("collection", credentials.id, credentials.data)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(COLLECTION, data);
          resolve(data.data);
          state.loading = false;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading = false;
        });
    });
  },
  [DELETE_COLLECTION](context, id) {
    state.loading = true;
    return new Promise(resolve => {
      SecureApiService.delete("collection", id)
        .then(({ data }) => {
          state.loading = false;
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
        });
    });
  },
  [RECREATE_COLLECTION](context, id) {
    return new Promise(resolve => {
      state.loading = true;
      SecureApiService.get("collection/recreate", id)
        .then(({ data }) => {
          state.loading = false;
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
        });
    });
  },
  [UPDATE_COLLECTION](context, id) {
    return new Promise(resolve => {
      state.loading = true;
      SecureApiService.get("collection/update", id)
        .then(({ data }) => {
          state.loading = false;
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
        });
    });
  },
  [PULL_COLLECTIONS](context) {
    state.loading = true;
    return SecureApiService.get("collections")
      .then(({ data }) => {
        state.loading = false;
        context.commit(COLLECTIONS, data.data);
      })
      .catch(() => {
        state.loading = false;
        context.commit(SET_MESSAGE, {
          err: 1,
          msg: i18n.global.t("internal_error", "api_errors")
        });
      });
  },
  [PULL_COLLECTION](context, id) {
    state.loading = true;
    return SecureApiService.get("collection", id)
      .then(({ data }) => {
        context.commit(COLLECTION, data);
        state.loading = false;
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          context.commit(SET_MESSAGE, response.data.errors);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
        state.loading = false;
      });
  },
  [PULL_COLLECTIONS_PREDEFINED](context) {
    return SecureApiService.get("collections-predefined")
      .then(({ data }) => {
        context.commit(COLLECTIONS_PREDEFINED, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data.errors);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
      });
  }
};

const mutations = {
  [CLOSE_DIALOG_ACTION](state) {
    state.showDialog = false;
    state.collection = new Collection(0, "", false);
  },
  [COLLECTION_SELECTED_ACTION](state, collection) {
    state.collectionSelected = collection;
  },
  [COLLECTIONS](state, data) {
    if (data.length > 0) {
      state.needCollection = false;
      state.collectionSelected =
        state.collectionSelected != null && state.collectionSelected.id > 0
          ? state.collectionSelected
          : data[0];
    } else {
      state.needCollection = true;
      state.collectionSelected = null;
    }
    state.loading = false;
    state.collections = data;
  },
  [COLLECTION_RELOAD_DATA](state, data) {
    state.collectionSelected = null;
    if (data && data.length > 0) {
      state.needCollection = false;
      state.collectionSelected = data[0];
    } else {
      state.needCollection = true;
      state.collectionSelected = null;
    }
    state.loading = false;
    state.collections = data;
  },
  [COLLECTION](state, data) {
    state.showDialog = true;
    state.collection = new Collection(
      data.data.id,
      data.data.name,
      data.data.force_stopwords,
      data.data.relevant_rate,
      data.data.mapping
    );
  },
  [COLLECTIONS_PREDEFINED](state, data) {
    let buffer = [];

    data.forEach(function(item) {
      buffer.push(
        new CollectionPredefined(
          item.id,
          item.name,
          item.description,
          item.schema,
          false
        )
      );
    });

    state.collections_predefined = buffer;
  },
  [REFRESH_ACTION](state) {
    state.collection = new Collection(0, "", false, 70);
    state.collections_predefined = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
