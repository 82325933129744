const state = {
  languages: {
    cs: "Czech",
    sk: "Slovak",
    de: "German",
    pl: "Polish",
    hu: "Hungarian",
    sl: "Slovenian",
    hr: "Croatian",
    sr: "Serbian",
    ro: "Romanian",
    uk: "Ukrainian",
    bg: "Bulgarian",
    lt: "Lithuanian",
    lv: "Latvian",
    ru: "Russian",
    bs: "Bosnian",
    sq: "Albanian",
    mk: "Macedonian",
    fr: "French",
    it: "Italian",
    el: "Greek",
    nl: "Dutch",
    da: "Danish",
    no: "Norwegian",
    sv: "Swedish",
    fi: "Finnish",
    et: "Estonian",
    tr: "Turkish",
    he: "Hebrew",
    hy: "Armenian",
    ka: "Georgian",
    en: "English",
    ar: "Arabic",
    fa: "Persian",
    sw: "Swahili",
    xh: "Xhosa",
    zu: "Zulu",
    tl: "Tagalog",
    vi: "Vietnamese",
    ja: "Japanese",
    ko: "Korean",
    th: "Thai",
    id: "Indonesian",
    ms: "Malay"
  },
  currencies: {
    CZK: "Czech Koruna",
    EUR: "Euro",
    PLN: "Polish Zloty",
    HUF: "Hungarian Forint",
    CHF: "Swiss Franc",
    RON: "Romanian Leu",
    HRK: "Croatian Kuna",
    RSD: "Serbian Dinar",
    DKK: "Danish Krone",
    SEK: "Swedish Krona",
    NOK: "Norwegian Krone",
    BGN: "Bulgarian Lev",
    BAM: "Bosnia-Herzegovina Convertible Mark",
    GBP: "British Pound Sterling",
    AUD: "Australian Dollar",
    CAD: "Canadian Dollar",
    CNY: "Chinese Yuan",
    INR: "Indian Rupee",
    JPY: "Japanese Yen",
    RUB: "Russian Ruble",
    BRL: "Brazilian Real",
    MXN: "Mexican Peso",
    ARS: "Argentine Peso",
    MAD: "Moroccan Dirham",
    ZAR: "South African Rand",
    USD: "United States Dollar",
    TRY: "Turkish Lira",
    MYR: "Malaysian Ringgit",
    THB: "Thai Baht",
    IDR: "Indonesian Rupiah",
    NGN: "Nigerian Naira",
    PKR: "Pakistani Rupee",
    VND: "Vietnamese Dong",
    COP: "Colombian Peso",
    PEN: "Peruvian Sol",
    ETB: "Ethiopian Birr",
    LKR: "Sri Lankan Rupee",
    TWD: "New Taiwan Dollar",
    SGD: "Singapore Dollar",
    IQD: "Iraqi Dinar",
    KWD: "Kuwaiti Dinar"
  },
  orderBy: {
    desc: "Sestupně",
    asc: "Vzestupně"
  },
  project_types: {
    plugin: "Upsearch Plugin",
    "mastershop-api": "Mastershop FrontApi"
  }
};

export default {
  state
};
