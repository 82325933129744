// action types
import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import i18n from "@/core/plugins/vue-i18n";

export const POST_XML_FEED = "post/feeds/";
export const PUT_XML_FEED = "put/feeds/";
export const DELETE_FEED = "delete/feeds/";
export const RELOAD_FEED = "reload/feeds/";
export const PULL_XML_FEEDS = "get/feeds/";
export const PULL_XML_FEED = "get/feed/";
export const PULL_XML_FEED_TYPES = "get/feedtypes/";
export const PULL_ERROR_DATA = "get/error_data/";

export const XML_FEEDS = "feedlist";
export const XML_FEED = "feed";
export const XML_FEED_MAPPING = "feed_mapping";
export const XML_FEED_TYPES = "feedtypes";
export const SET_ERROR_DATA = "set_error_data";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

class XmlFeed {
  constructor(
    name,
    feed_type_id,
    feed_url,
    last_import,
    mapping,
    activated,
    need_import,
    tag_name,
    collection_id,
    collection_name,
    total_count,
    fields = []
  ) {
    this.name = name;
    this.feed_type_id = feed_type_id;
    this.feed_url = feed_url;
    this.last_import = last_import;
    this.activated = activated;
    this.mapping = mapping;
    this.need_import = need_import;
    this.tag_name = tag_name;
    this.collection_id = collection_id;
    this.collection_name = collection_name;
    this.need_tag_name = false;
    this.total_count = total_count;
    this.fields = fields;
    switch (this.tag_name) {
      case "google":
        this.source = "google";
        break;
      case "heureka":
        this.source = "heureka";
        break;
      case "mastershop":
        this.source = "mastershop";
        break;
      default:
        this.source = "custom";
        break;
    }
  }
}

export class XmlFeedSchema {
  constructor(schema, field_name) {
    this.schema = schema;
    this.field_name = field_name;
  }
}

const state = {
  feeds: [],
  feed_types: [],
  errors: [],
  hasMessage: false,
  loading: true,
  message: "",
  error_data: [],
  model: new XmlFeed("", 1, "", "", {}, true, false, "", 0, "", 0)
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [POST_XML_FEED](context, credentials) {
    //state.loading = true;
    return new Promise(resolve => {
      SecureApiService.post("feeds", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          switch (data.err) {
            case 0:
              context.commit(XML_FEED, data);
              break;
            case 2:
              context.commit(XML_FEED, data);
              context.commit(XML_FEED_MAPPING);
              break;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          //state.loading = false;
        });
    });
  },
  [PUT_XML_FEED](context, credentials) {
    //state.loading = true;
    return new Promise(resolve => {
      SecureApiService.update("feeds", credentials.id, credentials.data)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          switch (data.err) {
            case 0:
              context.commit(XML_FEED, data);
              break;
            case 2:
              context.commit(XML_FEED, data);
              context.commit(XML_FEED_MAPPING);
              break;
          }
          resolve(data.data);
          //state.loading = false;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          //state.loading = false;
        });
    });
  },
  [RELOAD_FEED](context, id) {
    return new Promise(resolve => {
      SecureApiService.update("feed/reload", id)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          switch (data.err) {
            case 0:
              context.commit(XML_FEED, data);
              break;
            case 2:
              context.commit(XML_FEED, data);
              context.commit(XML_FEED_MAPPING);
              break;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
        });
    });
  },
  [DELETE_FEED](context, id) {
    return new Promise(resolve => {
      SecureApiService.delete("feeds", id)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }

          resolve(response);
        });
    });
  },
  [PULL_XML_FEEDS](context) {
    state.loading = true;
    return SecureApiService.get("feeds")
      .then(({ data }) => {
        context.commit(XML_FEEDS, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
      });
  },
  [PULL_XML_FEED](context, id) {
    state.loading = true;

    return new Promise(resolve => {
      return SecureApiService.get("feed", id)
        .then(({ data }) => {
          switch (data.err) {
            case 0:
              context.commit(XML_FEED, data);
              break;
            case 2:
              context.commit(XML_FEED, data);
              context.commit(XML_FEED_MAPPING);
              break;
          }
          state.loading = false;
          resolve(data);
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading = false;
        });
    });
  },
  [PULL_ERROR_DATA](context, id) {
    state.loading = true;

    return new Promise(resolve => {
      return SecureApiService.get("feed/" + id + "/error_data")
        .then(({ data }) => {
          switch (data.err) {
            case 0:
              context.commit(SET_ERROR_DATA, data);
              break;
          }
          state.loading = false;
          resolve(data);
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading = false;
        });
    });
  },
  [PULL_XML_FEED_TYPES](context) {
    return SecureApiService.get("feed_types")
      .then(({ data }) => {
        context.commit(XML_FEED_TYPES, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
      });
  }
};

const mutations = {
  [XML_FEEDS](state, data) {
    state.loading = false;
    state.feeds = data;
  },
  [XML_FEED](state, data) {
    state.model = new XmlFeed(
      data.data.name,
      data.data.feed_type_id,
      data.data.feed_url,
      data.data.last_import,
      data.data.mapping,
      data.data.activated,
      data.data.need_import,
      data.data.tag_name,
      data.data.collection_id,
      data.data.collection_name,
      data.data.total_count,
      data.fields
    );
  },
  [XML_FEED_MAPPING](state) {
    state.model.need_tag_name = true;
  },
  [SET_ERROR_DATA](state, data) {
    state.loading = false;
    state.error_data = data.data;
  },
  [XML_FEED_TYPES](state, data) {
    state.feed_types = data;
  },
  [REFRESH_ACTION](state) {
    state.feeds = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
    state.model = new XmlFeed("", 1, "", "", {}, true, false, "", 0, 0);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
