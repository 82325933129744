import Vue from "vue";
import Vuex from "vuex";

import message from "./message.module";
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import project from "./project.module";
import elastic from "./elastic.module";
import xmlfeed from "./xmlfeed.module";
import querytester from "./query_tester.module";
import documents from "./documents.module";
import schema from "./schema.module";
import synonyms from "./synonyms.module";
import curations from "./curations.module";
import relevanceTuning from "./relevance_tuning.module";
import resultSetting from "./result_settings.module";
import stopwords from "./stopwords.module";
import fixit from "./fixit.module";
import collections from "./collections.module";
import searchengine from "./searchengine.module";
import apilogs from "./api_logs.module";
import users from "./users.module";
import searchHistory from "./search_history.module";
import phrases from "./phrases.module";
import date_range from "./date_range.module";
import compare_results from "./compare_results.module";
import chars_filter from "./chars_filter.module";
import check_items from "./check_items.module";
import dashboard from "./dashboard.module";
import shoptet from "./shoptet.module";
import schema_autocomplete from "./schema_autocomplete.module";
import upsearch_doc from "./upsearch_doc.module";
import app_settings from "./app_settings.module";
import endpoint_configuration from "./endpoint_configuration.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    message,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    project,
    elastic,
    xmlfeed,
    querytester,
    documents,
    schema,
    synonyms,
    curations,
    relevanceTuning,
    resultSetting,
    stopwords,
    fixit,
    collections,
    searchengine,
    apilogs,
    users,
    searchHistory,
    phrases,
    date_range,
    compare_results,
    chars_filter,
    check_items,
    dashboard,
    shoptet,
    schema_autocomplete,
    upsearch_doc,
    app_settings,
    endpoint_configuration
  }
});
