// USA
export const locale = {
  sales_channel: {
    dialog: {
      add_new: {
        title: "Přidat nový projekt",
        you_are_invated: "Byli jste pozváni do následujících projektů:",
        or_create_new: "Nebo vytvořte nový projekt:",
        actions: {
          add_new: "Přidat nový",
          creating: "Vytvářím"
        },
        fields: {
          name: {
            label: "Název projektu",
            rule: "Název je povinný"
          },
          url: {
            label: "URL webu",
            hint: "Zadejte URL webu, kde chcete Upsearch používat"
          },
          copy: {
            label: "Kopírovat z existujícího projektu",
            hint: "Vyberte projekt, ze kterého chcete zkopírovat nastavení",
            hint2: "Zrušit kopírování"
          },
          language: {
            label: "Jazyk",
            rule: "Jazyk je povinný"
          },
          source: {
            label: "Zdroj dat",
            rule: "Zdroj dat je povinný"
          },
          feed_url: {
            label: "URL feedu",
            hint: "Zkopírujte sem URL odkaz hlavního feedu. Např. https://www.mujweb.cz/data/xml/feed.xml"
          },
          feed_availability_url: {
            label: "URL dostupnostního feedu",
            hint: "Zkopírujte sem URL odkaz dostupnostního feedu. Např. https://www.mujweb.cz/data/xml/availability.xml"
          },
          feed_type: {
            label: "Typ feedu",
            rule: "Typ feedu je povinný"
          },
          feed_language: {
            label: "Jazyk feedu",
            rule: "Jazyk feedu je povinný"
          },
          feed_encoding: {
            label: "Kódování feedu",
            rule: "Kódování feedu je povinný"
          },
          feed_separator: {
            label: "Oddělovač v feedu",
            rule: "Oddělovač v feedu je povinný"
          },
          feed_headers: {
            label: "Hlavičky v feedu",
            rule: "Hlavičky v feedu jsou povinné"
          },
          feed_headers_hint: "Zadejte hlavičky oddělené čárkou"
        }
      }
    }
  },
  forget_password: {
    title: "Zapomenuté heslo?",
    subtitle: "Zadejte svůj e-mail pro obnovení hesla.",
    fields: {
      email: {
        label: "E-mail",
        placeholder: "E-mail",
        validation: {
          empty: "E-mail je povinný",
          regex: "Hodnota není platná e-mailová adresa"
        }
      }
    }
  },
  signin: {
    title: "Přihlásit se do UpSearch",
    signup: {
      title: "Jste tu poprvé?",
      link: "Vytvořit účet"
    },
    actions: {
      forget_password: "Zapomenuté heslo?"
    },
    fields: {
      email: {
        label: "E-mail",
        placeholder: "E-mail",
        validation: {
          empty: "E-mail je povinný"
        }
      },
      password: {
        label: "Heslo",
        placeholder: "Heslo",
        hint:
          "Minimálně osm znaků, alespoň jedno velké písmeno,<br>jedno malé písmeno, jedna číslice a jeden speciální znak.",
        validation: {
          empty: "Heslo je povinné",
          length: "Heslo musí obsahovat alespoň 8 znaků",
          regex: "Použijte znaky s kombinací písmen"
        }
      }
    }
  },
  signup: {
    title: "Vytvořit účet",
    signin: {
      title: "Už máte účet?",
      link: "Přihlaste se zde"
    },
    fields: {
      first_name: {
        label: "Jméno",
        placeholder: "Jméno",
        required: "Jméno je povinné"
      },
      last_name: {
        label: "Příjmení",
        placeholder: "Příjmení",
        required: "Příjmení je povinné"
      },
      email: {
        label: "E-mail",
        placeholder: "E-mail",
        validation: {
          empty: "E-mail je povinný",
          regex: "Hodnota není platná e-mailová adresa"
        }
      },
      password: {
        label: "Heslo",
        placeholder: "Heslo",
        hint:
          "Minimálně osm znaků, alespoň jedno velké písmeno,<br>jedno malé písmeno, jedna číslice a jeden speciální znak.",
        validation: {
          empty: "Heslo je povinné",
          length: "Heslo musí obsahovat alespoň 8 znaků",
          regex: "Použijte znaky s kombinací písmen"
        }
      },
      confirm_password: {
        label: "Potvrďte heslo",
        placeholder: "Potvrďte heslo",
        validation: {
          empty: "Potvrzení hesla je povinné",
          identical: "Heslo a jeho potvrzení se neshodují"
        }
      },
      agree_terms: {
        agree_terms_text: "Souhlasím s",
        agree_terms_link: "obchodními podmínkami",
        dialog: {
          title: "Obchodní podmínky",
          body:
            "<p>Zde můžete vložit svůj aktuální text obchodních podmínek...</p>"
        },
        required: "Musíte souhlasit s obchodními podmínkami"
      }
    }
  },
  frontapi: {
    endpoints: {
      title: "Konfigurace endpointů",
      subtitle: "",
      actions:{
        swagger: "Dokumentace swagger",
      },
      dialog_model: {
        title: "Konfigurace cache",
        fields: {
          is_cached: {
            label: "Aktivní cache",
            rule: ""
          },
          cache_ttl: {
            label: "Cache TTL [s]",
            rule: "",
            hint: "Nastavit v sekundách"
          },
          cache_grace_period: {
            label: "Grace period cache [s]",
            rule: "",
            hint: "Nastavit v sekundách"
          }
        }
      },
      list: {
        table: {
          headers: {
            path: "Cesta",
            method: "Metoda",
            is_cached: "Cache",
            cache_ttl: "Cache TTL",
            cache_grace_period: "Grace period cache",
            actions: ""
          }
        }
      }
    }
  },
  boost_manager: {
    title: "Boost manager",
    subtitle: "Správa relevance pro objekty",
    modal: {
      help: {
        title: "Nápověda",
        text1: "Zjistěte podrobnější informace o modulu Boost Manager na",
        text2: "následujícím odkazu"
      },
      add_boost: {
        title: "Vyberte objekt pro boost",
        subtitle:
          "Vyberte objekt, kterému chcete přiřadit boost pro optimalizaci jeho relevance " +
          "ve výsledcích vyhledávání.",
        fields: {
          select_item: {
            label: "Vyhledat objekt"
          }
        },
        actions: {
          select_object: "Zvolit objekt"
        }
      }
    },
    dialogs: {
      publish: {
        title: "Jste si jistí?",
        content:
          "Kliknutím na souhlas tento boost bude nasazen do produkce. Jste si jisti?",
        actions: {
          save: "Ano, uložte to!"
        },
        after_save: {
          title: "Uloženo!",
          content:
            "Vaši zákazníci nyní prozkoumávají vaše produkty s novým boostováním."
        }
      }
    },
    functions: {
      function: {
        title: "Pokročilé nastavení boostu",
        fields: {
          function: {
            items: {
              logarithmic: "Logaritmická",
              exponential: "Exponenciální",
              linear: "Lineární"
            },
            label: "Funkce",
            hint: {
              linear:
                "Lineární – Hodnota roste rovnoměrně, změna má stálý dopad. Vhodné pro " +
                "předvídatelné úpravy.",
              logarithmic:
                "Logaritmická – Rychlý nárůst na začátku, ale pak se efekt zpomaluje. " +
                "Dobré pro vyvážený boost bez extrémních skoků.",
              exponential:
                "Exponenciální – Čím vyšší vstupní hodnota, tím větší skok. Vhodné pro " +
                "silné zvýhodnění vyšších hodnot."
            }
          },
          operation: {
            items: {
              add: "přičíst",
              multiply: "vynásobit"
            },
            label: "Operace",
            hint:
              "Specifikuje, zda se vypočtená hodnota přičte k relevanci, nebo ji vynásobí."
          }
        }
      },
      boost: {
        title: "Boost"
      },
      relevance_influence: {
        title: "Vliv na relevanci",
        subtitle:
          "Klikněte pro, aby se boost dostal i přes přednastavenou relevantní váhu."
      },
      relevance_level: "Váha dle relevance",
      less_visible: "méně viditelné",
      more_visible: "více viditelné"
    },
    short_description:
      "Boost manager vám umožňuje přesně spravovat relevanci objektů ve výsledcích vyhledávání a " +
      "filtrování produktů. Umožňuje vám upřednostnit určité značky, produktové kategorie, nejprodávanější " +
      "produkty a další. Zvýšte hodnocení klíčových objektů pro lepší viditelnost nebo snižte jejich prioritu, " +
      "aby byly méně výrazné.",
    toolbar: {
      action_discard_changes: "Zrušit úpravy",
      action_save: "Uložit a zveřejnit",
      collection: "Kolekce"
    },
    preview: {
      title: "Náhled",
      search: "Vyhledat...",
      time_took: "Čas běhu"
    },
    manage: {
      title: "Nastavení boostu",
      subtitle: "Pro změnu typu zvolte kolekci pomocí nabídky vpravo nahoře.",
      add_function: "Pokročilé nastavení boostu",
      add_boost: "Přidat boost",
      no_boost_set: "Přidejte první boost",
      not_available:
        "Žádné dostupné objekty k boostování. Přidejte nové značky, kategorie nebo parametry produktů, abyste mohli využít funkci boostování."
    }
  },
  dashboard: {
    title: "Nástěnka",
    subtitle: "Pro změnu data, jazyka nebo typu použijte filtr vpravo nahoře."
  },
  documents: { title: "Dokumenty" },
  upsearch_documents: {
    title: "Upsearch dokumenty",
    subtitle:
      "Přehled jednotlivých dokumentů uložených v Upsearch, přičemž některá data mohou být uložena také v Elasticsearch."
  },
  synonyms: {
    list: {
      table: {
        search_field: "Hledat v synonymech",
        headers: {
          catalog_term: "Výraz",
          terms: "Synonyma",
          synonym_type_id: "Schéma",
          actions: ""
        }
      }
    },
    dialog_model: {
      action: {
        new_record: "Nový záznam",
        add_value: "+ Přidat další",
        remove: "Odstranit"
      },
      title: {
        add: "Přidat sadu synonym",
        edit: "Upravit sadu synonym"
      },
      info1:
        'Ekvivalentní synonyma (uživatel zadá "autodráha", nebo "hot wheels". Na oba výrazy jsou nalezeny položky, které mají v názvu alespoň jeden z výrazů)',
      info2:
        'Nahrazení synonym katalogovým výrazem (uživatel zadá "apple, drahý telefon" => vyhledávání proběhne s výrazem "iphone")',
      info3:
        'Nahrazení výrazu synonymy (uživatel zadá "vůně" => vyhledávání proběhne s výrazy "deodorant, toaletní voda")',
      fields: {
        catalog_term: {
          label: "Zadejte výraz",
          rule: "Povinný údaj"
        },
        type: {
          label: "Typ nastavení synonyma",
          rule: "Povinný údaj",
          select: {
            option1: 'Ekvivalentní synonyma ( "autodráha" = "hot wheels")',
            option2:
              'Nahrazení synonym výrazem ("apple, drahý telefon" => "iphone")',
            option3:
              'Nahrazení výrazu synonymy ("vůně" => "deodorant, toaletní voda")'
          }
        },
        name: {
          label: "Zadejte synonymum",
          rule: "Povinný údaj"
        }
      }
    },
    title: "Synonyma",
    subtitle:
      "Očekáváte, že uživatelé budou vyhledávat určité položky pod různými výrazy? Nastavte synonyma a usnadněte jim vyhledávání.\n" +
      "Není nutné rozlišovat diakritiku nebo velikost písmen. Výchozí algoritmus si také poradí i se základními překlepy."
  },
  projects: {
    title: "Projekty",
    subtitle: "",
    list: {
      table: {
        search_field: "Hledat projekt dle názvu",
        headers: {
          name: "Název",
          actions: ""
        },
        actions: {
          change: "Přepnout projekt",
        },
      }
    }
  },
  fixit: {
    title: "Nahrazující výraz",
    subtitle:
      "Váš eshop neprodává položky, které na něm uživatele hledají? Nabídněte jim alternativu, nebo je přesměrujte na konkrétní URL.",
    dialog_model: {
      title: "Editace",
      fields: {
        search_term: {
          label: "Nahrazovaný výraz",
          hint: "Lze použít také regulární výraz",
          rule: "Povinný údaj"
        },
        catalog_term: {
          label: "Nahrazující výraz nebo URL",
          rule: "Povinný údaj",
          url: {
            text1: "Při zadání výrazu",
            text2: "bude přesměrován",
            text3: "na URL",
            text4: "kliknutím z autocomplete."
          },
          replace: {
            text1: "Při zadání výrazu",
            text2: "budou zobrazeny výsledky",
            text3: "pro výraz"
          }
        }
      },
      action: {
        new_record: "Nový záznam"
      }
    },
    list: {
      table: {
        search_field: "Hledat v záznamech",
        headers: {
          fixit_definition: "Pravidlo nahrazení",
          rule_executed: "Počet použití pravidla",
          users_rule_executed: "Počet unikátních uživatelů",
          actions: ""
        },
        from_this_query: "Výraz",
        to_url: "Přesměruje na URL",
        to_query: "Nahradit výrazem"
      }
    }
  },
  curration: {
    title: "Přizpůsobení výsledků",
    subtitle:
      "Potřebujete na konkrétní výraz nabídnout položky, které by se na základě shody v názvu nenašly?\n" +
      "Nebo naopak potřebujete, aby se některé položky na konkrétní výraz nenabízely?\n" +
      "Přizpůsobte si ručně výsledky vyhledávání.",
    edit: {
      title: "Nastavení přizpůsobení výsledků",
      subtitle: "Pro výrazy",
      search: {
        value: "Hledat"
      },
      promoted: {
        title: "Propagované",
        subtitle:
          "Propagované položky se zobrazí před organickými výsledky. Položky můžete ze seznamu odebrat, nebo změnit jejich pořadí.",
        box: {
          info: "Přidejte položky kliknutím na ikonu hvěždičky"
        }
      },
      organic: {
        title: "nalezených položek pro výraz",
        subtitle:
          "Vyhledejte položky. Poté je propagujte kliknutím na hvězdičku, nebo skryjte kliknutím na přeškrtnuté oko.",
        box: {
          info: "Nenalezeny žádné položky"
        }
      },
      hidden: {
        title: "Skryté",
        subtitle: "Skryté položky se nezobrazí ve výsledcích vyhledávání.",
        box: {
          info: "Přidejte položky kliknutím na ikonu škrtnutého oka"
        }
      }
    },
    list: {
      table: {
        search_field: "Hledat v záznamech",
        headers: {
          name: "Interní název",
          queries: "Hledané výrazy",
          promoted: "Zobrazit produkty",
          hidden: "Skrýt produkty",
          actions: ""
        }
      }
    },
    dialog: {
      model: {
        title: "Přizpůsobení výsledků",
        subtitle:
          "Pojmenujte úpravu a přidejte jeden nebo více výrazů. Nastavení položek pro skrytí nebo zobrazení se provede v dalším kroku.",
        action: {
          add_record: "Přidat záznam",
          edit_record: "Upravit výrazy"
        },
        saved: {
          title: "Přidáno!",
          content:
            "O půlnoci se nastaví ve vyhledávači. Pro okamžitou změnu klikněte na `Přegenerovat` vpravo nahoře."
        },
        fields: {
          name: {
            label: "Interní název (pouze pro účely administrace)",
            rule: "Povinný údaj"
          },
          query: {
            label: "Výraz",
            rule: "Povinný údaj"
          }
        }
      }
    }
  },
  tools: {
    elastic_reload: {
      action: {
        generate: "Přegenerovat"
      },
      title: "Jste si jisti?",
      text: "Při přegenerování se vypne na pár sekund vyhledání.",
      confirm: "Ano, přegenerovat!",
      cancel: "Zavřít",
      done: "Přegenerováno!"
    }
  },
  stopwords: {
    title: "Ignorované výrazy",
    subtitle:
      "Výrazy, které se mají v rámci vyhledávání ignorovat. Typicky se jedná o předložky či spojky." +
      'Pokud prodáváme knihu "Tipy pro zahradníky" a stopwords by nebyly nasazeny, při zadání "tipy pro" by byl nalezen i produkt "iPhone 16 Pro", protože obsahuje slovo "pro".' +
      "Použijte předdefinovanou sadu předložek a spojek, nebo přidejte ručně vlastní výraz.",
    dialog: {
      predefined: {
        title: "Přidat předdefinovanou sadu",
        action: {
          add_set: "Použít sadu"
        },
        fields: {
          type: {
            label: "Vyberte jazyk",
            rule: "Povinný údaj"
          }
        }
      },
      model: {
        title: "Přidat ignorovaný výraz",
        action: {
          add_record: "Přidat záznam"
        },
        saved: {
          title: "Přidáno!",
          content:
            "O půlnoci se nastaví ve vyhledávači. Pro okamžitou změnu klikněte na `Přegenerovat` vpravo nahoře."
        },
        fields: {
          term: {
            label: "Zadejte výraz",
            rule: "Povinný údaj"
          }
        }
      }
    },
    list: {
      table: {
        search_field: "Hledat ve stopwords",
        headers: {
          term: "Výraz",
          actions: ""
        }
      }
    }
  },
  chars_filter: {
    title: "Chars Filter",
    subtitle: ""
  },
  credentials: {
    title: "Credentials",
    subtitle: ""
  },
  query_tester: {
    title: "Testování dotazů",
    search_field: "Hledat",
    results: {
      item: "Položka",
      score: "Skóre",
      category: "Kategorie",
      brand: "Značka",
      price: "Cena",
      relevant_variants_cnt: "Počet variant",
      action: "Akce"
    }
  },
  analytics: {
    title: "ANALÝZA",
    trending_searches: {
      widget: {
        table: {
          title: "Top #10 dotazů",
          subtitle:
            "Seřazeno podle počtu unikátních uživatelů, kteří výraz ve zvoleném časovém období hledali."
        }
      },
      title: "Trendy ve vyhledávání",
      subtitle:
        "Tyto výrazy zadávali uživatelé ve zvoleném časovém období nejčastěji.",
      no_result: "V tomto období nejsou žádné výsledky",
      no_result_subtitle: "Pro změnu období klikněte na kalendář vpravo nahoře",
      list: {
        timeline: {
          title: "Nejčastěji vyhledávané výrazy",
          subtitle: "",
          data_title: "Celkem dotazů",
          average_search_query_time: "Průměrný čas vrácení výsledků",
          search_terms: "Vyhledáno unikátních výrazů",
          unique_queries: "Unikátních návštěvníků"
        },
        table: {
          title: "Trendy výsledky",
          subtitle:
            "Kliknutím na detail výrazu zobrazíte trend vyhledávání v čase a nalezené položky.",
          search_field: "Vyhledat dotaz",
          loading: "Co kdo hledal... načítání...",
          headers: {
            result_type: "Typ",
            search_query: "Dotaz",
            results_avg: "Počet výsledků (průměr)",
            phrase_results_avg: "Přesná shoda (průměr)",
            queries: "Zobrazení",
            unique_queries: "Uživatelů",
            fixits: "Fixitů"
          }
        }
      },
      item: {
        timeline: {
          title: "Trend hledání",
          data_title: "Celkem uživatelů hledalo"
        },
        table: {
          title: "Položky dotazu",
          loading: "Načítání zklamaných zákazníků... Načítání...",
          search_field: "Vyhledat dotaz",
          headers: {
            result_type: "Typ",
            position_avg: "Umístění (průměr)",
            object_type: "Kolekce",
            item: "Položka",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        },
        table_fixit: {
          title: "Tento dotaz je přesměrován jinam",
          loading: "načítání...",
          headers: {
            fixit_definition: "Fixit definice",
            rule_executed: "Použito",
            actions: "Funkce"
          }
        },
        no_result: "Na tento dotaz momentálně nemáte žádné výsledky"
      }
    },
    trending_items: {
      title: "Trendy položek",
      subtitle:
        "Položky, které se ve výsledcích vyhledávání objevovaly ve zvoleném časovém období nejčastěji.",
      list: {
        table: {
          title: "Nejčastěji nalezené položky",
          subtitle:
            'Sloupec "Umístění (průměr)" říká, na kterém místě výsledků se daná položka v průměru vypisovala.',
          loading: "Načítání...",
          search_field: "Název položky",
          headers: {
            result_type: "Typ",
            item_name: "Položka",
            collection_name: "Kolekce",
            position_avg: "Umístění (průměr)",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        }
      },
      item: {
        timeline: {
          title: "Trend dotazu",
          data_title: "Celkem dotazů"
        },
        table: {
          title: "Umístění položek",
          loading: "načítání...",
          search_field: "Název položky",
          headers: {
            result_type: "Typ",
            position_avg: "Umístění (průměr)",
            query: "Dotaz",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        }
      }
    },
    searches_without_results: {
      title: "Hledání bez výsledků",
      subtitle: "Výrazy, které nenajdou žádný výsledek.",
      widget: {
        table: {
          title: "Top #10 dotazů",
          subtitle:
            'Seřazeno podle počtu unikátních uživatelů, kteří výraz ve zvoleném časovém období hledali. Tlačítkem "Vyřešit tento problém" můžete ručně nastavit nahrazující výraz.'
        }
      },
      no_result: "V tomto období nejsou žádné výsledky",
      no_result_subtitle: "Pro změnu období klikněte na kalendář vpravo nahoře",
      list: {
        timeline: {
          title: "Dotazů s nulovým výsledkem",
          data_title: "Dotazů"
        },
        table: {
          title: "Vyhledáváné výrazy",
          subtitle:
            'Tlačítkem "Vyřešit tento problém" můžete ručně nastavit nahrazující výraz.',
          loading: "načítání...",
          search_field: "Vyhledat dotaz",
          headers: {
            result_type: "Typ",
            search_query: "Dotaz",
            queries: "Zobrazení",
            unique_queries: "Uživatelů",
            fixit: "Fixit"
          }
        },
        percent_of_no_results:
          "všech dotazů na Vašem projektu skončilo neúspěšně",
        search_terms: "výrazů nenašlo výsledky"
      }
    }
  },
  result_settings: {
    types: { all: "Vše", autocomplete: "Našeptávač", search: "Vyhledávání" }
  },
  search_settings: {
    fixit: {
      buttons: {
        create: "Založit",
        show_fixit: "Show fixit",
        fix_this_problem: "Vyřešit tento problém"
      },
      from_this_query: "Z tohoto dotazu",
      to_query: "Na tento",
      your_query_is_routed_away: "Tento dotaz je přesměrován jinam"
    }
  },
  common: {
    logout: "Odhlásit se",
    skip: "Přeskočit",
    yes: "Ano",
    no: "Ne",
    hours: "hodin",
    nodata: "Žádná data",
    filter: "Filtrovat",
    show_more: "Zobrazit více",
    reset: "Zkusit znova",
    new_record: "Nový záznam",
    edit: "Editovat",
    delete: "Smazat",
    remove: "Smazat",
    close: "Zavřít",
    save: "Uložit",
    back: "Zpět",
    submit: "Odeslat",
    cancel: "Zrušit",
    actions: "Akce",
    continue: "Pokračovat",
    add_value: "Přidat další",
    indicates_required_field: "* povinné pole",
    dialog_delete: {
      title: "Upozornění",
      content: "Opravdu chcete daný záznam smazat?",
      confirm: "Ano",
      cancel: "Zpět",
      success: "Smazáno"
    },
    dialog_validate: {
      content: "Prosím, poskytněte správná data!"
    }
  },
  sources: {
    shoptet: {
      name: "Shoptet"
    },
    customized: {
      name: "Vlastní feed"
    },
    google: {
      name: "Google Feed (produkty)"
    },
    heureka: {
      name: "Heureka Feed (produkty)"
    },
    upsearch: {
      name: "Upsearch Feed"
    },
    frontapi: {
      name: "Upsearch Rest Api"
    }
  },
  datepicker: {
    apply_label: "Použít",
    cancel_label: "Zrušit",
    custom_range: "Vlastní rozsah",
    ranges: {
      yesterday: "Včera",
      last_3_days: "Posl. 3 dny",
      last_7_days: "Posl. 7 dnů",
      last_30_days: "Posl. 30 dnů",
      last_60_days: "Posl. 60 dnů",
      last_90_days: "Posl. 90 dnů"
    }
  },
  days: {
    sun: "Ne",
    mon: "Po",
    tue: "Út",
    wed: "St",
    thu: "Čt",
    fri: "Pá",
    sat: "So"
  },
  months: {
    jan: "Leden",
    feb: "Únor",
    mar: "Březen",
    apr: "Duben",
    may: "Květen",
    jun: "Červen",
    jul: "Červenec",
    aug: "Srpen",
    sep: "Září",
    oct: "Říjen",
    nov: "Listopad",
    dec: "Prosinec"
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    SEARCH_SETTINGS: "NASTAVENÍ HLEDÁNÍ"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  api_errors: {
    internal_error: "Interní chyba, zkuste to prosím znovu za několik minut."
  },
  user_profile: {
    hi: "Dobrý den",
    user_profile: "Profil uživatele",
    sign_out: "Odhlásit se",
    link_edit_profile: "Upravit profil"
  },
  profile: {
    title: "Profil",
    contact: {
      email: "Email",
      phone: "Telefon",
      company: "Název společnosti"
    },
    nav: {
      personal_info: "Osobní údaje",
      change_password: "Změnit heslo",
      deactivate_account: "Deaktivovat účet",
      email_settings: "Nastavení emailu",
      my_projects: "Moje projekty"
    },
    personal_info: {
      title: "Osobní údaje",
      subtitle: "Aktualizujte své osobní údaje",
      sections: {
        my_info: "Moje informace",
        contact_info: "Kontaktní údaje"
      },
      fields: {
        avatar: "Profilový obrázek",
        first_name: "Jméno",
        last_name: "Příjmení",
        company_name: "Název společnosti",
        job_position: "Pracovní pozice",
        contact_phone: "Kontaktní telefon",
        phone: "Telefon",
        phone_hint: "Váš email nikdy nesdílíme s nikým jiným.",
        email_address: "Emailová adresa",
        email: "Email",
        allowed_files: "Povolené typy souborů: png, jpg, jpeg."
      },
      actions: {
        save: "Uložit změny",
        cancel: "Zrušit",
        change_avatar: "Změnit profilový obrázek",
        cancel_avatar: "Zrušit změnu profilového obrázku",
        remove_avatar: "Odstranit profilový obrázek"
      }
    },
    change_password: {
      title: "Změna hesla",
      subtitle: "Změňte heslo svého účtu",
      actions: {
        save: "Uložit změny",
        cancel: "Zrušit"
      },
      fields: {
        current_password: "Aktuální heslo",
        new_password: "Nové heslo",
        verify_password: "Potvrzení hesla"
      },
      validation: {
        current_password_required: "Aktuální heslo je povinné",
        new_password_required: "Nové heslo je povinné",
        new_password_length: "Heslo musí mít minimálně 6 znaků",
        verify_password_required: "Potvrzení hesla je povinné",
        passwords_not_match: "Hesla se neshodují",
        please_provide_correct_data: "Prosím, zadejte správná data!"
      }
    },
    deactivate_account: {
      title: "Deaktivace účtu",
      actions: {
        deactivate: "Deaktivovat",
        cancel: "Zrušit"
      },
      fields: {
        password: "Vaše heslo",
        verify: "Napište 'i want deactivate'",
        verify_placeholder: "Napište 'i want deactivate'"
      },
      validation: {
        password_required: "Heslo je povinné",
        verify_required: "Potvrzení je povinné",
        verify_not_match: "Potvrzení se neshoduje",
        please_provide_correct_data: "Prosím, zadejte správná data!"
      }
    }
  },
  footer: {
    made_by: "Vytvořeno společností",
    support: "Podpora",
    sign_out: "Odhlásit se"
  }
};
