import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
import cs from "vuetify/src/locale/cs.ts";
import en from "vuetify/src/locale/en.ts";

const storedLanguage = localStorage.getItem("language");
let lang;

if (storedLanguage) {
  lang = storedLanguage;
} else {
  const browserLang = navigator.language || navigator.userLanguage;
  lang =
    browserLang && browserLang.toLowerCase().startsWith("cs") ? "cs" : "en";
}

export default new Vuetify({
  lang: {
    locales: { cs, en },
    current: lang
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#5867dd",
        secondary: "#e8ecfa",
        accent: "#5d78ff",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822"
      }
    }
  }
});
