import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import SecureApiService from "../secureapi.service";
import { SET_CURRENT_SALES_CHANNEL } from "./project.module";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import i18n from "@/core/plugins/vue-i18n";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGET_PASSWORD = "forgetPass";
export const DEACTIVATE_ACCOUNT = "deactivateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";
export const NEED_SALES_CHANNEL = "needsaleschannel";
export const SET_NEED_SALES_CHANNEL = "setNeedSalesChannel";
export const SET_IS_AUTH = "setIsAuth";
export const SET_IS_LOADING = "setIsLoading";

const state = {
  errors: null,
  hasMessage: false,
  message: "",
  needSalesChannel: false,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  loading: {
    signin: false,
    signup: false,
    repass: false
  }
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          if (data.err === 0) {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          } else {
            context.commit(SET_MESSAGE, data);
          }
          state.loading.signin = false;
        })
        .catch(({ response }) => {
          if (response) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading.signin = false;
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          if (data.err === 0) {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          } else {
            context.commit(SET_MESSAGE, data);
          }
          state.loading.signup = false;
        })
        .catch(({ response }) => {
          if (response) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: i18n.global.t("internal_error", "api_errors")
            });
          }
          state.loading.signup = false;
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      SecureApiService.get("verify")
        .then(({ data }) => {
          if (data.err === 0) {
            context.commit(SET_AUTH, data.data);
            context.commit(SET_CURRENT_SALES_CHANNEL, data.sales_channel);
            state.needSalesChannel = false;
          } else if (data.err === 2) {
            context.commit(NEED_SALES_CHANNEL);
          }
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(PURGE_AUTH);
          }
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put("user/password", payload)
      .then(({ data }) => {
        context.commit(SET_MESSAGE, data);
        return data;
      })
      .catch(({ response }) => {
        if (response && response.msg) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
      });
  },
  [DEACTIVATE_ACCOUNT](context, payload) {
    return ApiService.put("user/deactivate", payload)
      .then(({ data }) => {
        context.commit(SET_MESSAGE, data);
        return data;
      })
      .catch(({ response }) => {
        if (response && response.msg) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: i18n.global.t("internal_error", "api_errors")
          });
        }
      });
  },
  [FORGET_PASSWORD](context, credentials) {
    return ApiService.post("forgetpass", credentials).then(({ data }) => {
      state.loading.repass = false;
      context.commit(SET_MESSAGE, data);
    });
  },
  [NEED_SALES_CHANNEL]({ commit }, needSalesChannel) {
    commit(SET_NEED_SALES_CHANNEL, needSalesChannel);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [NEED_SALES_CHANNEL](state) {
    state.needSalesChannel = true;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_NEED_SALES_CHANNEL](state, needSalesChannel) {
    state.needSalesChannel = needSalesChannel;
  },
  [SET_IS_AUTH](state, isAuth) {
    state.isAuthenticated = isAuth;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.loading = isLoading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
