// USA
export const locale = {
  sales_channel: {
    dialog: {
      add_new: {
        title: "Add New Project",
        you_are_invated: "You have been invited to the following project:",
        or_create_new: "Or create a new project:",
        actions: {
          add_new: "Add New",
          creating: "Creating"
        },
        fields: {
          name: {
            label: "Project Name",
            rule: "Name is required"
          },
          url: {
            label: "Website URL",
            hint: "Enter the URL of the website where you want to search"
          },
          copy: {
            label: "Copy from existing project",
            hint: "Select a project to copy settings from",
            hint2: "Cancel copying"
          },
          language: {
            label: "Language",
            rule: "Language is required"
          },
          source: {
            label: "Data Source",
            rule: "Data source is required"
          },
          feed_url: {
            label: "Feed URL",
            hint: "Copy the URL of the main feed here. E.g. https://www.mywebsite.com/data/xml/feed.xml"
          },
          feed_availability_url: {
            label: "Availability Feed URL",
            hint: "Copy the URL of the availability feed here. E.g. https://www.mywebsite.com/data/xml/availability.xml"
          },
          feed_type: {
            label: "Feed Type",
            rule: "Feed type is required"
          },
          feed_language: {
            label: "Feed Language",
            rule: "Feed language is required"
          },
          feed_encoding: {
            label: "Feed Encoding",
            rule: "Feed encoding is required"
          },
          feed_separator: {
            label: "Feed Separator",
            rule: "Feed separator is required"
          },
          feed_headers: {
            label: "Feed Headers",
            rule: "Feed headers are required"
          },
          feed_headers_hint: "Enter headers separated by commas"
        }
      }
    }
  },
  forget_password: {
    title: "Forgot Password ?",
    subtitle: "Enter your email to reset your password.",
    fields: {
      email: {
        label: "Email",
        placeholder: "Email",
        validation: {
          empty: "Email is required",
          regex: "The value is not a valid email address"
        }
      }
    }
  },
  signin: {
    title: "Sign In to UpSearch",
    signup: {
      title: "New Here?",
      link: "Create an Account"
    },
    actions: {
      forget_password: "Forgot Password ?"
    },
    fields: {
      email: {
        label: "Email",
        placeholder: "Email",
        validation: {
          empty: "Email is required"
        }
      },
      password: {
        label: "Password",
        placeholder: "Password",
        hint:
          "Minimum eight characters, at least one uppercase letter,<br>one" +
          "lowercase letter, one number and one special character.",
        validation: {
          empty: "Password is required",
          length: "The password must have at least 8 characters",
          regex: "Use characters with a mix of letters"
        }
      }
    }
  },
  signup: {
    title: "Create an Account",
    signin: {
      title: "Already have an account?",
      link: "Sign in here"
    },
    fields: {
      first_name: {
        label: "First Name",
        placeholder: "Firstname",
        required: "Firstname is required"
      },
      last_name: {
        label: "Last Name",
        placeholder: "Lastname",
        required: "Lastname is required"
      },
      email: {
        label: "Email",
        placeholder: "Email",
        validation: {
          empty: "Email is required",
          regex: "The value is not a valid email address"
        }
      },
      password: {
        label: "Password",
        placeholder: "Password",
        hint:
          "Minimum eight characters, at least one uppercase letter,<br>one" +
          "lowercase letter, one number and one special character.",
        validation: {
          empty: "Password is required",
          length: "The password must have at least 8 characters",
          regex: "Use characters with a mix of letters"
        }
      },
      confirm_password: {
        label: "Confirm Password",
        placeholder: "Confirm Password",
        validation: {
          empty: "Confirm password is required",
          identical: "The password and its confirm are not the same"
        }
      },
      agree_terms: {
        agree_terms_text: "I agree to the",
        agree_terms_link: "terms and conditions",
        dialog: {
          title: "Terms and Conditions",
          body:
            "<p>Here you can put your actual terms and conditions text...</p>"
        },
        required: "You should agree terms and conditions"
      }
    }
  },
  frontapi: {
    endpoints: {
      title: "Endpoint Configuration",
      subtitle: "",
      actions:{
        swagger: "Swagger Docs",
      },
      dialog_model: {
        title: "Cache Configuration",
        fields: {
          is_cached: {
            label: "Active Cache",
            rule: ""
          },
          cache_ttl: {
            label: "Cache TTL [s]",
            rule: "",
            hint: "Set in seconds"
          },
          cache_grace_period: {
            label: "Cache Grace Period [s]",
            rule: "",
            hint: "Set in seconds"
          }
        }
      },
      list: {
        table: {
          headers: {
            path: "Path",
            method: "Method",
            is_cached: "Cached",
            cache_ttl: "Cache TTL",
            cache_grace_period: "Cache Grace Period",
            actions: ""
          }
        }
      }
    }
  },
  boost_manager: {
    title: "Boost Manager",
    subtitle: "Managing relevance for objects",
    modal: {
      help: {
        title: "Help",
        text1:
          "Find more detailed information about the Boost Manager module at",
        text2: "the following link"
      },
      add_boost: {
        title: "Select Object for Boost",
        subtitle:
          "Select the object you want to assign a boost to optimize its relevance in search results.",
        fields: {
          select_item: {
            label: "Search for object"
          }
        },
        actions: {
          select_object: "Select object"
        }
      }
    },
    dialogs: {
      publish: {
        title: "Are you sure?",
        content:
          "By clicking agree, this boost will be deployed to production. Are you sure?",
        actions: {
          save: "Yes, save it!"
        },
        after_save: {
          title: "Saved!",
          content:
            "Your customers can now explore your items with the new boost applied."
        }
      }
    },
    functions: {
      function: {
        title: "Advanced Boost Settings",
        fields: {
          function: {
            items: {
              logarithmic: "Logarithmic",
              exponential: "Exponential",
              linear: "Linear"
            },
            label: "Function",
            hint: {
              linear:
                "Linear – The value grows evenly, with a consistent impact. Suitable for " +
                "predictable adjustments.",
              logarithmic:
                "Logarithmic – Rapid increase at the beginning, but the effect slows " +
                "down later. Good for balanced boosts without extreme jumps.",
              exponential:
                "Exponential – The higher the input value, the greater the jump. Suitable " +
                "for strong prioritization of higher values."
            }
          },
          operation: {
            items: {
              add: "add",
              multiply: "multiply"
            },
            label: "Operation",
            hint:
              "Specifies whether the calculated value is added to or multiplied by the relevance."
          }
        }
      },
      boost: {
        title: "Boost"
      },
      relevance_influence: {
        title: "Relevance Influence",
        subtitle:
          "Click to allow the boost to override the preset relevance weight."
      },
      relevace_level: "Weight by relevance",
      less_visible: "less visible",
      more_visible: "more visible"
    },
    short_description:
      "The Boost Manager allows you to precisely manage the relevance of objects in search " +
      "results and product filtering. It enables you to prioritize certain brands, product categories, " +
      "best-selling products, and more. Increase the ranking of key objects for better visibility or decrease " +
      "their priority to make them less prominent.",
    toolbar: {
      action_discard_changes: "Discard changes",
      action_save: "Save and publish",
      collection: "Collection"
    },
    preview: {
      title: "Preview",
      search: "Search...",
      time_took: "Time taken"
    },
    manage: {
      title: "Boost Settings",
      subtitle:
        "To change the type, select a collection using the menu at the top right.",
      add_function: "Advanced boost settings",
      add_boost: "Add boost",
      no_boost_set: "Add your first boost",
      not_available:
        "No available objects to boost. Add new brands, categories, or product parameters to use the boosting feature."
    }
  },
  dashboard: {
    title: "Dashboard",
    subtitle:
      "To change the date, language, or type, use the filter in the top right."
  },
  collections: {
    title: "Stacks",
    dialog_model: {
      title: "Stack",
      subtitle:
        "Stacks are groups of diverse content types (products, articles, brands) distributed by Upsearch to the web.",
      fields: {
        name: {
          label: "Name",
          rule: "Required Field"
        },
        dependent_by: {
          label: "Dependent by",
          rule: "Required Field"
        }
      },
      action: {
        new_record: "New Record"
      }
    }
  },
  documents: { title: "Documents" },
  upsearch_documents: {
    title: "Upsearch Documents",
    subtitle:
      "An overview of individual documents stored in Upsearch, with some data potentially residing in Elasticsearch."
  },
  query_tester: {
    title: "Query tester",
    search_field: "Search",
    results: {
      item: "Item",
      score: "Score",
      category: "Category",
      brand: "Brand",
      price: "Price",
      relevant_variants_cnt: "Cnt variants",
      action: "Action"
    }
  },
  analytics: {
    title: "ANALYSIS",
    trending_searches: {
      widget: {
        table: {
          title: "Top #10 Queries",
          subtitle:
            "Sorted by the number of unique users who searched for the term within the selected time period."
        }
      },
      title: "Search Trends",
      subtitle:
        "These terms were most frequently entered by users during the selected time period.",
      no_result: "No results for this period",
      no_result_subtitle:
        "To change the period, click on the calendar in the top right",
      list: {
        timeline: {
          title: "Most Frequently Searched Terms",
          subtitle: "",
          data_title: "Total Queries",
          average_search_query_time: "Average Query Response Time",
          search_terms: "Unique Search Terms",
          unique_queries: "Unique Visitors"
        },
        table: {
          title: "Trending Results",
          subtitle:
            "Click on a query detail to see its search trend over time and found items.",
          search_field: "Search Query",
          loading: "What people searched for... loading...",
          headers: {
            result_type: "Type",
            search_query: "Query",
            results_avg: "Number of Results (Avg)",
            phrase_results_avg: "Exact Match (Avg)",
            queries: "Views",
            unique_queries: "Users",
            fixits: "Fixits"
          }
        }
      },
      item: {
        timeline: {
          title: "Search Trend",
          data_title: "Total Users Searched"
        },
        table: {
          title: "Query Items",
          loading: "Loading disappointed customers... Loading...",
          search_field: "Search Query",
          headers: {
            result_type: "Type",
            position_avg: "Position (Avg)",
            object_type: "Collection",
            item: "Item",
            queries: "Views",
            unique_queries: "Users"
          }
        },
        table_fixit: {
          title: "This Query is Redirected Elsewhere",
          loading: "loading...",
          headers: {
            fixit_definition: "Fixit Definition",
            rule_executed: "Applied",
            actions: "Functions"
          }
        },
        no_result: "There are currently no results for this query"
      }
    },
    trending_items: {
      title: "Trending Items",
      subtitle:
        "Items that appeared most frequently in search results during the selected time period.",
      list: {
        table: {
          title: "Most Frequently Found Items",
          subtitle:
            "The 'Position (Avg)' column indicates the average ranking of the item in the results.",
          loading: "Loading...",
          search_field: "Item Name",
          headers: {
            result_type: "Type",
            item_name: "Item",
            collection_name: "Collection",
            position_avg: "Position (Avg)",
            queries: "Views",
            unique_queries: "Users"
          }
        }
      },
      item: {
        timeline: {
          title: "Query Trend",
          data_title: "Total Queries"
        },
        table: {
          title: "Item Positions",
          loading: "loading...",
          search_field: "Item Name",
          headers: {
            result_type: "Type",
            position_avg: "Position (Avg)",
            query: "Query",
            queries: "Views",
            unique_queries: "Users"
          }
        }
      }
    },
    searches_without_results: {
      title: "Searches Without Results",
      subtitle: "Terms that return no results.",
      widget: {
        table: {
          title: "Top #10 Queries",
          subtitle:
            "Sorted by the number of unique users who searched for the term within the selected time period. Use the 'Fix this problem' button to manually set a replacement term."
        }
      },
      no_result: "No results for this period",
      no_result_subtitle:
        "To change the period, click on the calendar in the top right",
      list: {
        timeline: {
          title: "Queries with Zero Results",
          data_title: "Queries"
        },
        table: {
          title: "Searched Terms",
          subtitle:
            "Use the 'Fix this problem' button to manually set a replacement term.",
          loading: "loading...",
          search_field: "Search Query",
          headers: {
            result_type: "Type",
            search_query: "Query",
            queries: "Views",
            unique_queries: "Users",
            fixit: "Fixit"
          }
        },
        percent_of_no_results:
          "of all queries on your project ended unsuccessfully",
        search_terms: "terms found no results"
      }
    }
  },
  result_settings: {
    types: { all: "All", autocomplete: "Autocomplete", search: "Search" }
  },
  search_settings: {
    fixit: {
      buttons: {
        create: "Create",
        show_fixit: "Show Fixit",
        fix_this_problem: "Fix this problem"
      },
      from_this_query: "From this query",
      to_query: "To this",
      your_query_is_routed_away: "This query is redirected elsewhere"
    }
  },
  synonyms: {
    list: {
      table: {
        search_field: "Search in synonyms",
        headers: {
          catalog_term: "Term",
          terms: "Synonyms",
          synonym_type_id: "Scheme",
          actions: ""
        }
      }
    },
    dialog_model: {
      action: {
        new_record: "New record",
        add_value: "+ Add another",
        remove: "Remove"
      },
      title: {
        add: "Add synonym set",
        edit: "Edit synonym set"
      },
      info1:
        'Equivalent synonyms (the user enters "race track" or "hot wheels". Items containing at least one of these terms in their name will be found)',
      info2:
        'Replacing synonyms with a catalog term (the user enters "apple, expensive phone" => search is performed using the term "iphone")',
      info3:
        'Replacing a term with synonyms (the user enters "fragrance" => search is performed using "deodorant, eau de toilette")',
      fields: {
        catalog_term: {
          label: "Enter term",
          rule: "Required field"
        },
        type: {
          label: "Synonym setting type",
          rule: "Required field",
          select: {
            option1: 'Equivalent synonyms ("race track" = "hot wheels")',
            option2:
              'Replacing synonyms with a term ("apple, expensive phone" => "iphone")',
            option3:
              'Replacing a term with synonyms ("fragrance" => "deodorant, eau de toilette")'
          }
        },
        name: {
          label: "Enter synonym",
          rule: "Required field"
        }
      }
    },
    title: "Synonyms",
    subtitle:
      "Do you expect users to search for certain items under different terms? Set up synonyms to make their search easier.\n" +
      "Diacritics and letter case do not need to be distinguished. The default algorithm also handles basic typos."
  },
    projects: {
        title: "Projects",
        subtitle: "",
        list: {
            table: {
                search_field: "Search for projects by name",
                headers: {
                    name: "Name",
                    actions: ""
                },
                actions: {
                    change: "Change project",
                },
            }
        }
    },
  fixit: {
    title: "Replacing Term",
    subtitle:
      "Does your e-shop not sell the items users are searching for? Offer them an alternative or redirect them to a specific URL.",
    dialog_model: {
      title: "Edit",
      fields: {
        search_term: {
          label: "Replaced Term",
          hint: "Regular expressions can also be used",
          rule: "Required Field"
        },
        catalog_term: {
          label: "Replacing Term or URL",
          rule: "Required Field",
          url: {
            text1: "If you enter search term",
            text2: "you will redirect",
            text3: "to URL",
            text4: "by click enter on autocomplete."
          },
          replace: {
            text1: "If you enter search term",
            text2: "you show results",
            text3: "by catalog term"
          }
        }
      },
      action: {
        new_record: "New Record"
      }
    },
    list: {
      table: {
        search_field: "Search in Records",
        headers: {
          fixit_definition: "Replacement Rule",
          rule_executed: "Number of Rule Uses",
          users_rule_executed: "Number of Unique Users",
          actions: ""
        },
        from_this_query: "Term",
        to_url: "Redirects to URL",
        to_query: "Replaced with Term"
      }
    }
  },
  curration: {
    title: "Search Result Customization",
    subtitle:
      "Do you need to display items for a specific query that wouldn't be found based on name matching?\n" +
      "Or do you need to prevent certain items from appearing for a specific query?\n" +
      "Manually adjust the search results.",
    edit: {
      title: "Search Result Customization Settings",
      subtitle: "For queries",
      search: {
        value: "Search"
      },
      promoted: {
        title: "Promoted",
        subtitle:
          "Promoted items will appear before organic results. You can remove items from the list or change their order.",
        box: {
          info: "Add items by clicking the star icon"
        }
      },
      organic: {
        title: "Found items for query",
        subtitle:
          "Search for items. Then promote them by clicking the star, or hide them by clicking the crossed-out eye.",
        box: {
          info: "No items found"
        }
      },
      hidden: {
        title: "Hidden",
        subtitle: "Hidden items will not appear in search results.",
        box: {
          info: "Add items by clicking the crossed-out eye icon"
        }
      }
    },
    list: {
      table: {
        search_field: "Search in records",
        headers: {
          name: "Internal Name",
          queries: "Search Queries",
          promoted: "Show Products",
          hidden: "Hide Products",
          actions: ""
        }
      }
    },
    dialog: {
      model: {
        title: "Search Result Customization",
        subtitle:
          "Name the adjustment and add one or more queries. Settings for hiding or displaying items will be done in the next step.",
        action: {
          add_record: "Add Record",
          edit_record: "Edit Queries"
        },
        saved: {
          title: "Added!",
          content:
            "It will be applied in the search engine at midnight. For an immediate change, click `Regenerate` in the top right corner."
        },
        fields: {
          name: {
            label: "Internal Name (for administrative purposes only)",
            rule: "Required field"
          },
          query: {
            label: "Query",
            rule: "Required field"
          }
        }
      }
    }
  },
  stopwords: {
    title: "Ignored Terms",
    subtitle:
      "Terms that should be ignored in search. Typically, these include prepositions or conjunctions. " +
      'For example, if we sell a books a lot of their names will start with "The". If stopwords were not ' +
      'applied, search results for "The Art Book" would also contain many other non-relevant books ' +
      'with "The" in the title. Use a predefined set of prepositions and conjunctions or manually ' +
      "add your own terms.",
    dialog: {
      predefined: {
        title: "Add a Predefined Set",
        action: {
          add_set: "Use Set"
        },
        fields: {
          type: {
            label: "Select Language",
            rule: "Required Field"
          }
        }
      },
      model: {
        title: "Add Ignored Term",
        action: {
          add_record: "Add Record"
        },
        saved: {
          title: "Added!",
          content:
            "It will be applied in the search engine at midnight. To apply immediately, click 'Regenerate' in the top right."
        },
        fields: {
          term: {
            label: "Enter Term",
            rule: "Required Field"
          }
        }
      }
    },
    list: {
      table: {
        search_field: "Search in Stopwords",
        headers: {
          term: "Term",
          actions: ""
        }
      }
    }
  },
  chars_filter: {
    title: "Character Filter",
    subtitle: ""
  },
  credentials: {
    title: "Credentials",
    subtitle: ""
  },
  common: {
    yes: "Yes",
    no: "No",
    hours: "hours",
    nodata: "No data",
    filter: "Filter",
    show_more: "Show more",
    reset: "Try again",
    new_record: "New record",
    edit: "Edit",
    delete: "Delete",
    remove: "Remove",
    close: "Close",
    accept: "Accept",
    decline: "Decline",
    save: "Save",
    skip: "Skip",
    submit: "Submit",
    continue: "Continue",
    logout: "Logout",
    cancel: "Cancel",
    back: "Back",
    actions: "Actions",
    add_value: "Add Value",
    indicates_required_field: "* required field",
    dialog_delete: {
      title: "Warning",
      content: "Are you sure you want to delete this record?",
      confirm: "Yes",
      cancel: "Back",
      success: "Deleted"
    },
    dialog_validate: {
      content: "Please, provide correct data!"
    }
  },
  sources: {
    shoptet: {
      name: "Shoptet"
    },
    customized: {
      name: "Customized"
    },
    google: {
      name: "Google Feed (products)"
    },
    heureka: {
      name: "Heureka Feed (products)"
    },
    upsearch: {
      name: "Upsearch Feed"
    },
    frontapi: {
      name: "Upsearch Rest Api"
    }
  },
  tools: {
    elastic_reload: {
      action: {
        generate: "Regenerate"
      },
      title: "Are you sure?",
      text: "Regenerating will disable search for a few seconds.",
      confirm: "Yes, regenerate!",
      cancel: "Close",
      done: "Regenerated!"
    }
  },
  datepicker: {
    apply_label: "Apply",
    cancel_label: "Cancel",
    custom_range: "Custom Range",
    ranges: {
      yesterday: "Yesterday",
      last_3_days: "Last 3 days",
      last_7_days: "Last 7 days",
      last_30_days: "Last 30 days",
      last_60_days: "Last 60 days",
      last_90_days: "Last 90 days"
    }
  },
  days: {
    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat"
  },
  months: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December"
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    SEARCH_SETTINGS: "SEARCH SETTINGS"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  api_errors: {
    internal_error: "Internal error, please try again a few minutes later."
  },
  user_profile: {
    hi: "Hello",
    user_profile: "User Profile",
    sign_out: "Sign out",
    link_edit_profile: "Edit Profile"
  },
  profile: {
    title: "Profile",
    contact: {
      email: "Email",
      phone: "Phone",
      company: "Company name"
    },
    nav: {
      personal_info: "Personal Information",
      change_password: "Change Password",
      deactivate_account: "Deactivate Account",
      email_settings: "Email Settings",
      my_projects: "My Projects"
    },
    personal_info: {
      title: "Personal Information",
      subtitle: "Update your personal information",
      sections: {
        my_info: "My Information",
        contact_info: "Contact Information"
      },
      fields: {
        avatar: "Profile Picture",
        first_name: "First Name",
        last_name: "Last Name",
        company_name: "Company Name",
        job_position: "Job Position",
        contact_phone: "Contact Phone",
        phone: "Phone",
        phone_hint: "We never share your email with anyone else.",
        email_address: "Email Address",
        email: "Email",
        allowed_files: "Allowed file types: png, jpg, jpeg."
      },
      actions: {
        save: "Save Changes",
        cancel: "Cancel",
        change_avatar: "Change Profile Picture",
        cancel_avatar: "Cancel Profile Picture Change",
        remove_avatar: "Remove Profile Picture"
      }
    },
    change_password: {
      title: "Change Password",
      subtitle: "Change your account password",
      actions: {
        save: "Save Changes",
        cancel: "Cancel"
      },
      fields: {
        current_password: "Current Password",
        new_password: "New Password",
        verify_password: "Verify Password"
      },
      validation: {
        current_password_required: "Current password is required",
        new_password_required: "New password is required",
        new_password_length: "Password must be at least 6 characters",
        verify_password_required: "Password verification is required",
        passwords_not_match: "Passwords do not match",
        please_provide_correct_data: "Please provide correct data!"
      }
    },
    deactivate_account: {
      title: "Deactivate Account",
      actions: {
        deactivate: "Deactivate",
        cancel: "Cancel"
      },
      fields: {
        password: "Your Password",
        verify: "Type 'i want deactivate'",
        verify_placeholder: "Type 'i want deactivate'"
      },
      validation: {
        password_required: "Password is required",
        verify_required: "Verification is required",
        verify_not_match: "Verification does not match",
        please_provide_correct_data: "Please provide correct data!"
      }
    }
  },
  footer: {
    made_by: "Made by",
    support: "Support",
    sign_out: "Sign Out"
  }
};
