import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import SecureApiService from "@/core/services/secureapi.service";

// mutation types
export const GET_AUTOCOMPLETE = "[get]autocomplete";
export const GET_AUTOCOMPLETE_QUERY = "GET_AUTOCOMPLETE_QUERY";
export const GET_SERP_QUERY = "GET_SERP_QUERY";
export const GET_CATALOG_QUERY = "GET_CATALOG_QUERY";
export const PUT_AUTOCOMPLETE = "[put]autocomplete";

export const GET_SEARCH = "[get]search";
export const GET_CATALOG_INFOS = "[get]catalogInfos";
export const PUT_SEARCH = "[put]search";

export const AUTOCOMPLETE = "autocomplete";
export const AUTOCOMPLETE_QUERY = "AUTOCOMPLETE_QUERY";
export const SERP_QUERY = "SERP_QUERY";
export const CATALOG_QUERY = "CATALOG_QUERY";
export const SEARCH = "search";
export const CATALOG = "CATALOG";
export const GET_CATALOG = "GET_CATALOG";
export const PUT_CATALOG = "PUT_CATALOG";

export const PULL_LAYOUT_COLUMNS = "[pull]layoutColumns";
export const GET_LIST_COLLECTIONS = "[get]collections";
export const LAYOUT_COLUMNS = "layout_columns";
export const COLLECTIONS = "collections";
export const CATALOG_INFOS = "catalog_infos";
export const LOAD_REDIS_ROW = "LOAD_REDIS_ROW";
export const DOWNLOAD_CATALOG_DATA = "DOWNLOAD_CATALOG_DATA";
export const REDIS_ROW = "REDIS_ROW";

const state = {
  autocomplete: [],
  loading: {
    autocomplete: {
      query: false,
      data: false
    },
    search: {
      query: false,
      data: false
    },
    catalog: {
      query: false,
      data: false
    }
  },
  search: [],
  catalog: [],
  catalog_selected: {},
  catalog_infos: [],
  layout_columns: [],
  collections: []
};

class Collection {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

const getters = {};

const actions = {
  [GET_AUTOCOMPLETE](context, data) {
    return ApiService.get("/result-settings/autocomplete", data).then(
      ({ data }) => {
        context.commit(AUTOCOMPLETE, data.result_settings.autocomplete);
      }
    );
  },
  [GET_AUTOCOMPLETE_QUERY](context, data) {
    return ApiService.get("/result-settings/autocomplete/query", data).then(
      ({ data }) => {
        context.commit(AUTOCOMPLETE_QUERY, data.result_settings.autocomplete);
      }
    );
  },
  [GET_SERP_QUERY](context, data) {
    return ApiService.get("/result-settings/serp/query", data).then(
      ({ data }) => {
        context.commit(SERP_QUERY, data.result_settings.search);
      }
    );
  },
  [GET_CATALOG_QUERY](context, data) {
    return ApiService.get("/result-settings/catalog/query", data).then(
      ({ data }) => {
        context.commit(CATALOG_QUERY, data.result_settings.catalog);
      }
    );
  },
  [GET_LIST_COLLECTIONS](context) {
    return ApiService.get("/collections").then(({ data }) => {
      context.commit(COLLECTIONS, data.data);
    });
  },
  [GET_CATALOG_INFOS](context) {
    return ApiService.get("/result-settings/catalog-info/all").then(
      ({ data }) => {
        context.commit(CATALOG_INFOS, data.data);
      }
    );
  },
  [LOAD_REDIS_ROW](context, id) {
    return ApiService.get("/redis/load", id).then(({ data }) => {
      context.commit(REDIS_ROW, data.redis);
    });
  },
  [DOWNLOAD_CATALOG_DATA]() {
    return new Promise(resolve => {
      return SecureApiService.get(
        "/result-settings/catalog-info/download"
      ).then(({ data }) => {
        resolve(data.link);
      });
    });
  },
  [PUT_AUTOCOMPLETE](context, data) {
    return ApiService.put("/result-settings/autocomplete", data).then(
      ({ data }) => {
        context.commit(AUTOCOMPLETE, data.result_settings.autocomplete);
        context.commit(SET_MESSAGE, data);
      }
    );
  },
  [GET_SEARCH](context, data) {
    return ApiService.get("/result-settings/search", data).then(({ data }) => {
      context.commit(SEARCH, data.result_settings.search);
    });
  },
  [GET_CATALOG](context, data) {
    return ApiService.get("/result-settings/catalog", data).then(({ data }) => {
      context.commit(CATALOG, data.result_settings.catalog);
    });
  },
  [PUT_CATALOG](context, data) {
    return ApiService.put("/result-settings/catalog", data).then(({ data }) => {
      context.commit(CATALOG, data.result_settings.catalog);
      context.commit(SET_MESSAGE, data);
    });
  },
  [PUT_SEARCH](context, data) {
    return ApiService.put("/result-settings/search", data).then(({ data }) => {
      context.commit(SEARCH, data.result_settings.search);
      context.commit(SET_MESSAGE, data);
    });
  },
  [PULL_LAYOUT_COLUMNS](context) {
    return ApiService.get("/layout-columns/").then(({ data }) => {
      context.commit(LAYOUT_COLUMNS, data.data);
    });
  }
};

const mutations = {
  [AUTOCOMPLETE](state, data) {
    state.autocomplete = data;
    state.loading.autocomplete.data = false;
  },
  [AUTOCOMPLETE_QUERY](state, data) {
    state.autocomplete.results = data.results;
    state.loading.autocomplete.query = false;
  },
  [SERP_QUERY](state, data) {
    state.search.results = data.results;
    state.loading.search.query = false;
  },
  [CATALOG_QUERY](state, data) {
    state.catalog.results = data.results;
    state.loading.catalog.query = false;
  },
  [SEARCH](state, data) {
    state.search = data;
    state.loading.search.data = false;
  },
  [CATALOG](state, data) {
    state.catalog = data;
    state.loading.catalog.data = false;
  },
  [CATALOG_INFOS](state, data) {
    state.catalog_infos = data;
  },
  [REDIS_ROW](state, data) {
    state.catalog_selected = data;
  },
  [LAYOUT_COLUMNS](state, data) {
    state.layout_columns = data;
  },
  [COLLECTIONS](state, data) {
    state.collections = [];
    state.collections.push(new Collection(null, "without"));

    data.forEach(function(field) {
      state.collections.push(new Collection(field.id, field.name));
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
