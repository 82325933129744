import SecureApiService from "../secureapi.service";

// Action types
export const ENDPOINT_CONFIGURATION_PULL_LIST = "PULL_LIST";
export const ENDPOINT_CONFIGURATION_PULL_MODEL = "PULL_MODEL";
export const ENDPOINT_CONFIGURATION_PUT_MODEL = "PUT_MODEL";

export const ENDPOINT_CONFIGURATION_LIST = "LIST";
export const ENDPOINT_CONFIGURATION_MODEL = "MODEL";

export const SET_ERROR = "SET_ERROR";
export const SET_INFO = "SET_INFO";
export const SET_LOADING = "SET_LOADING";

export const REFRESH = "REFRESH";
export const REFRESH_ACTION = "REFRESH_ACTION";

export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const CLOSE_DIALOG_ACTION = "CLOSE_DIALOG_ACTION";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  showDialog: false,
  message: "",
  state: "",
  list: [],
  endpoint_configuration_model: {
    path: "",
    method: "",
    request_method: "",
    class_name: "",
    tag: "",
    is_cached: false,
    id: 0,
    cache_ttl: 0,
    cache_grace_period: 0
  },
  headers: []
};

const getters = {
  modelEndpointConfiguration: state => state.endpoint_configuration_model,
  isLoading: state => state.loading
};

const actions = {
  async [ENDPOINT_CONFIGURATION_PULL_LIST]({ commit }) {
    commit(SET_LOADING, true);
    try {
      const { data } = await SecureApiService.get("endpoints/configurations");
      commit(ENDPOINT_CONFIGURATION_LIST, data.payload);
    } catch (error) {
      commit(
        SET_ERROR,
        error.response?.data?.errors || [
          "Internal error, please try again later."
        ]
      );
    }
  },

  async [ENDPOINT_CONFIGURATION_PULL_MODEL]({ commit }, id) {
    commit(SET_LOADING, true);
    try {
      const { data } = await SecureApiService.get(
        `endpoints/configuration/${id}`
      );
      commit(ENDPOINT_CONFIGURATION_MODEL, data.payload);
    } catch (error) {
      commit(
        SET_ERROR,
        error.response?.data?.errors || [
          "Internal error, please try again later."
        ]
      );
    }
  },

  async [ENDPOINT_CONFIGURATION_PUT_MODEL]({ commit }, credentials) {
    commit(SET_LOADING, true);
    try {
      const { data } = await SecureApiService.update(
        "endpoints/configuration",
        credentials.id,
        credentials.data
      );
      commit(ENDPOINT_CONFIGURATION_MODEL, data.payload);
    } catch (error) {
      commit(
        SET_ERROR,
        error.response?.data?.errors || [
          "Internal error, please try again later."
        ]
      );
    }
  },

  [REFRESH]({ commit }) {
    commit(REFRESH_ACTION);
  },

  [CLOSE_DIALOG]({ commit }) {
    commit(CLOSE_DIALOG_ACTION);
  }
};

const mutations = {
  [SET_LOADING](state, isLoading) {
    state.loading = isLoading;
  },

  [SET_ERROR](state, errors) {
    state.loading = false;
    state.errors = errors;
  },

  [SET_INFO](state, message) {
    state.hasMessage = !!message;
    state.message = message || "";
  },

  [ENDPOINT_CONFIGURATION_LIST](state, data) {
    state.loading = false;
    state.list = data.endpoint_configurations;
  },

  [ENDPOINT_CONFIGURATION_MODEL](state, data) {
    state.state = "edit";
    state.loading = false;
    state.showDialog = true;
    Object.assign(
      state.endpoint_configuration_model,
      data.endpoint_configuration
    ); // Zachová existující klíče, ale přepíše hodnoty
  },

  [CLOSE_DIALOG_ACTION](state) {
    state.showDialog = false;
  },

  [REFRESH_ACTION](state) {
    state.state = "";
    state.endpoint_configuration_model = {
      path: "",
      method: "",
      request_method: "",
      class_name: "",
      tag: "",
      is_cached: false,
      id: 0,
      cache_ttl: 0,
      cache_grace_period: 0
    };
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
