import ApiService from "@/core/services/api.service";
import cookie from "@/assets/js/components/cookie";
import moment from "moment";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const PULL_PERSONAL_INFO = "user/data";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_STATS_USER_DATE_RANGE = "SET_STATS_USER_DATE_RANGE";
export const SET_STATS_USER_RESULT_OBJECT = "SET_STATS_USER_RESULT_OBJECT";
export const SET_STATS_USER_STORE = "SET_STATS_USER_STORE";

const state = {
  user_info: {
    photo: "",
    name: "James",
    surname: "Jones",
    company_name: "Fifestudios",
    job: "Application Developer",
    email: "matt@fifestudios.com",
    phone: "44(76)34254578",
    company_site: "fifestudios",
    is_admin: false,
    is_owner: false,
    user_groups: []
  },
  configuration: {
    stats: {
      result_object: "search",
      language: "cs",
      date_range: []
    }
  },
  loading: false
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_info;
  },
  currentUserConfiguration(state) {
    state.configuration.stats.date_range = {
      startDate: cookie.getCookie("startDate")
        ? new Date(moment(cookie.getCookie("startDate")).format())
        : new Date().setDate(new Date().getDate() - 8),
      endDate: cookie.getCookie("endDate")
        ? new Date(moment(cookie.getCookie("endDate")).format())
        : new Date().setDate(new Date().getDate() - 1)
    };

    state.configuration.stats.language = cookie.getCookie("statsLang")
      ? cookie.getCookie("statsLang")
      : "";

    state.configuration.stats.result_object = cookie.getCookie(
      "statsResultObject"
    )
      ? cookie.getCookie("statsResultObject")
      : "search";

    return state.configuration;
  },

  currentUserAccountInfo(state) {
    return state.user_info;
  },

  currentUserPhoto(state) {
    return state.user_info.photo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    state.loading = true;
    return ApiService.put("user/data", payload).then(({ data }) => {
      state.loading = false;
      context.commit(SET_PERSONAL_INFO, data.data);
    });
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [PULL_PERSONAL_INFO](context) {
    return ApiService.get("user/data").then(({ data }) => {
      context.commit(SET_PERSONAL_INFO, data.data);
    });
  },
  [SET_STATS_USER_DATE_RANGE](context, data) {
    cookie.setCookie("startDate", data.startDate);
    cookie.setCookie("endDate", data.endDate);
    state.configuration.stats.date_range = data;
  },
  [SET_STATS_USER_RESULT_OBJECT](context, data) {
    cookie.setCookie("statsResultObject", data);
    state.configuration.stats.result_object = data;
  },
  [SET_STATS_USER_STORE](context, data) {
    cookie.setCookie("statsLang", data.stats.language);
    cookie.setCookie("statsResultObject", data.stats.result_object);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
