// action types
import SecureApiService from "../secureapi.service";
import i18n from "@/core/plugins/vue-i18n";

export const PULL_PHRASES = "elastic/phrases";
export const PUT_PHRASE_VISIBLE = "put/phrases/visibility";
export const PUT_PHRASE_BOOST = "put/phrases/boost";
export const POST_PHRASE = "post/phrase";

export const PHRASES_DATA = "data";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  data: []
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_PHRASES](context) {
    state.loading = true;
    return SecureApiService.get("elastic/phrases")
      .then(({ data }) => {
        state.loading = false;
        context.commit(PHRASES_DATA, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [POST_PHRASE](context, data) {
    return new Promise(resolve => {
      SecureApiService.post("phrases", data)
        .then(({ data }) => {
          context.commit(SET_INFO, data.msg);
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              i18n.global.t("internal_error", "api_errors")
            ]);
          }
        });
    });
  },
  [PUT_PHRASE_VISIBLE](context, data) {
    state.loading = true;
    return SecureApiService.update("phrases/visibility", data.id, data)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  },
  [PUT_PHRASE_BOOST](context, data) {
    state.loading = true;
    return SecureApiService.update("phrases/boost", data.id, data)
      .then(() => {
        state.loading = false;
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            i18n.global.t("internal_error", "api_errors")
          ]);
        }
      });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [PHRASES_DATA](state, data) {
    state.loading = false;
    state.data = data.data;
  },
  [REFRESH_ACTION](state) {
    state.phrases = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
