// action types
import SecureApiService from "../secureapi.service";

export const PULL_FIXIT_LIST = "[get]fixits";
export const PULL_FIXIT = "[get]fixit";
export const PUT_FIXIT = "[put]fixit";
export const POST_FIXIT = "[post]fixit";
export const DELETE_FIXIT = "[delete]fixit";

export const FIXIT_LIST = "list";
export const FIXIT_MODEL = "model";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

export const CLOSE_DIALOG = "closeDialog";
export const CLOSE_DIALOG_ACTION = "closeDialogAction";

class FixIt {
  constructor(id, catalog_term = "", term = "") {
    this.id = id;
    this.catalog_term = catalog_term;
    this.term = term;
  }
}

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  showDialog: false,
  state: "",
  message: "",
  fixit: new FixIt(null, "", ""),
  items: []
};
const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [CLOSE_DIALOG](context) {
    context.commit(CLOSE_DIALOG_ACTION);
  },
  [PULL_FIXIT_LIST](context, data) {
    state.loading = true;
    return SecureApiService.get("fixits", "?" + data)
      .then(({ data }) => {
        context.commit(FIXIT_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [PULL_FIXIT](context, id) {
    return SecureApiService.get("fixits", id)
      .then(({ data }) => {
        context.commit(FIXIT_MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [DELETE_FIXIT](context, id) {
    state.loading = true;
    return SecureApiService.delete("fixits", id)
      .then(() => {})
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [PUT_FIXIT](context, credentials) {
    state.loading = true;
    return SecureApiService.update("fixits", credentials.id, credentials.data)
      .then(({ data }) => {
        context.commit(FIXIT_MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [POST_FIXIT](context, data) {
    return new Promise(resolve => {
      SecureApiService.post("fixits", data)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_ERROR, data.errors);
            return;
          }
          context.commit(SET_INFO, data.msg);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [FIXIT_LIST](state, data) {
    state.loading = false;
    state.items = data;
  },
  [FIXIT_MODEL](state, data) {
    state.state = "edit";
    state.showDialog = true;
    state.fixit = new FixIt(data.id, data.catalog_term, data.term);
  },
  [REFRESH_ACTION](state) {
    state.errors = [];
    state.fixit = new FixIt(0, "", "");
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
    state.state = "";
  },
  [CLOSE_DIALOG_ACTION](state) {
    state.showDialog = false;
  }
};

export default {
  state,
  actions,
  mutations
};
